import React, { useEffect, useState } from "react";
import {useDispatch,useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Input, Checkbox, Select, Progress, Modal, notification } from "antd";
import countryCode from '../../countryCode.json';
import { createUserApi, getGeoData } from "../../api/index"
import { RootState } from "../../redux/store";
import { addUserAndMemberId, addParentInfo, AssessmentType, setGeoData } from "../../redux/slices/assessSlice";

function ParentForm(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { questions, childInfo } = useSelector<RootState, AssessmentType>(state => state.assessment);

  const [ api, notificationContext ] = notification.useNotification();

  const { Option } = Select;
  const [ activeForm, setActiveForm ] = useState<any>({});
  const [ parentForm ] = Form.useForm();


  useEffect(() => {
    if(!questions.length) {
      history.push("/assessment");
      return;
    }
    let geodata: any;
    getGeoData().then((data) => {
      geodata = {
        state: data.stateProv,
        country: data.countryName,
        phoneCountryCode: countryCode.filter((val, index) => val.code === data.countryCode)[0].dial_code,
        city:data.city,
        ipaddress:data.ipAddress
      }
      dispatch(setGeoData(geodata));
    })
    .catch((err) => console.log(err, "Error"))
    .catch((err) => console.log(err));
  }, []);

  const onHandleCodeChange = (value: any) => {
    setActiveForm({ ...activeForm, "phoneCountryCode": value });
  }
  const prefixSelector = (
    <Form.Item name="phoneCountryCode" noStyle>
      <Select style={{ width: 100 }} onSelect={onHandleCodeChange}>
        {countryCode.map((val,index) =>
          <Option value={val.dial_code} key={index}>{val.dial_code}</Option>
        )}
      </Select>
    </Form.Item>
  );

  const onHandleChange = (e: any, name: string) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const submit = () => {
    const validateparent = parentForm.validateFields();
    Promise.resolve(validateparent).then(() => {
      dispatch(addParentInfo(activeForm));
      const surveyInfo = questions.map(qs => {
        const selectedRes = qs.qResponse.find((res: any) => res.fields.responseRef === qs.selectedResponse);
        return {
          questionRef: qs.fields.questionRef,
          question: qs.fields.question,
          responseRef: selectedRes.fields.responseRef,
          response: selectedRes.fields.response
        }
      });
      const childNames = childInfo.childName.split(" ");
      
      const data = {
        ...activeForm,
        socialSkillAssesment: surveyInfo,
        memberData : {
          ...childInfo,
          firstName: childNames[0],
          lastName: childNames[1] ?? ""
        }
      }
      delete(data.agreement);
      createUserApi(data).then((res) => {
        const { userId, memberId } = res.data;
        dispatch(addUserAndMemberId({ userId, memberId }));
        history.push("/result");
      }).catch(err => {
        if(err.response)
          api.error({
            message: err.response.data?.message
          });
        console.log(err);
      });
    })
    .catch(() => {
      console.log("error")
    });
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const oncheckboxchange=(e:any)=>{
    setActiveForm({...activeForm,agreement:e.target.checked});
  }

  const handlePrevious = () => {
    history.goBack();
  }
  return (
    <div className="personalize-form-container">
      { notificationContext }
      <Col span={24}>
        <div className="step-text-main">
          <p>Great... That was quick!</p>
          <p className="step-text-main span mt-3">Where should we send the assessment outcome.</p>
        </div>
        <Col className="personalize-form parent-form">
          <Form form={parentForm} layout="vertical">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="parentname"
                  label="Parent / Carer Name"
                >
                  <Row style={{marginTop:"-30px"}}>
                    <Col span={12} className="parent-form-firstname-col">
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        className="parent-form-firstname parent-name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Child's Name",
                          },
                          {
                            pattern: /^(?!\s)[a-zA-Z\s]+$/,
                            message: "Name must contain alphabets",
                          },
                        ]} 
                      >
                        <Input
                          name="firstName"
                          onChange={(e) => onHandleChange(e, "firstName")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="parent-form-lastname-col">
                      <Form.Item
                        name="lastName"
                        label="Last Name"
                        className="parent-form-lastname parent-name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Last Name",
                          },
                          {
                            pattern: /^(?!\s)[a-zA-Z\s]+$/,
                            message: "Name must contain alphabets",
                          },
                        ]} 
                      >
                        <Input
                          name="lastName"
                          onChange={(e) => onHandleChange(e, "lastName")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} className="personalize-form-age-col">
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    { required: true, message: "Please Enter Email" }
                  ]}
                >
                  <Input
                    name="email"
                    onChange={(e) => onHandleChange(e, "email")}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="personalize-form-gender-col personalize-form-phone-number">
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[{ required: true, message: "Please Enter Number" },
                  { pattern:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,message:'Please input valid Phone Number'}
                ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    name="phoneNumber"
                    maxLength={10}
                    onChange={(e) => onHandleChange(e, "phoneNumber")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error('Please Accept agreement')),
                  },
                ]}
              >
                <Checkbox onChange={oncheckboxchange} name="agreement" className="checkbox-parent-form" style={{color:"white"}}>
                  I agree to this <span className="checkbox-assessment" onClick={showModal}>Assessment terms and conditions</span>
                  <Modal style={{ top: 20 }} title="Terms & Conditions" cancelButtonProps={{ style: { display: 'none' } }} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, odit?</p>
                  </Modal>
                </Checkbox>
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Col>
      <Row className="button-footer" style={{paddingRight:"12px"}}>
        <Col span={4}>
          <div
            onClick={()=>handlePrevious()}
            className="previous-button previous-parent-button"
          >
            <a> Previous </a>
          </div>
        </Col>
        <Col span={11}>
          <Progress
            strokeColor="#FCB333"
            showInfo={false}
            width={400}
            percent={33.33 * 3}
            steps={3}
            className="parent-progress"
          ></Progress>
        </Col>
        <Col span={9} className="start-wizard-footer">
          <div
            onClick={submit}
            className="button-getting-launched"
          >
            <button className="getting-launched-button">Get Results</button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ParentForm;
